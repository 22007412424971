<script setup>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import Layout from '@/components/common/Layout.vue';
import Spinner from '@/components/common/Spinner.vue';
import Button from '@/components/common/Button.vue';
import Choices from '@/components/common/Choices.vue';
import companySubscriptionsService from '@/services/companySubscriptions.service';
import companyOffersService from '@/services/companyOffers.service';
import subscriptionOptionsMapper from '@/mappers/companySubscriptionOptions.mapper';
import useCurrentCompany from '@/composables/useCurrentCompany';

const { t } = useI18n();
const toast = useToast();

const { currentCompanyId } = useCurrentCompany();

const loading = ref(false);
const subscriptionOptions = ref([]);
const chosenOption = ref();

const subscriptionOptionChoices = computed(
  () => subscriptionOptions.value.map((opt) => subscriptionOptionsMapper.mapToChoiceOptions(t, opt)),
);

const fetchSubscriptionOptions = async () => {
  try {
    loading.value = true;
    subscriptionOptions.value = await companySubscriptionsService.fetchCompanySubscriptionOptions();
  } catch (err) {
    const [error] = err;

    toast.error(t(error));
  } finally {
    loading.value = false;
  }
};

const handleSubscribeClick = async () => {
  try {
    const response = await companyOffersService.subscribeToOffer(
      currentCompanyId.value,
      chosenOption.value,
    );

    if (response.redirectUrl) {
      window.location.href = response.redirectUrl;

      return;
    }

    toast.error(t('common.redirectionError'));
  } catch (err) {
    const [error] = err;

    toast.error(t(error));
  }
};

onMounted(() => {
  fetchSubscriptionOptions();
});
</script>

<template>
<Layout>
  <h1 class="mb-1 tac">{{ t('pages.subscriptionRecurrenceSetup.title') }}</h1>
  <p class="mb-2 tac">{{ t('pages.subscriptionRecurrenceSetup.description') }}</p>
  <div :class="$style.loaderWrapper" v-if="loading">
    <Spinner />
  </div>
  <Choices
    v-else
    v-model="chosenOption"
    :options="subscriptionOptionChoices"
    name="subscriptionRecurrence"
    :containerClassname="$style.subscriptionOptions"
  />

  <Button class="mt-2 tar mr-2" :disabled="!chosenOption" @buttonClick="handleSubscribeClick">
    {{ t('common.confirm') }}
  </Button>
</Layout>
</template>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.loaderWrapper {
  margin-top: 6rem;
  
  display: flex;
  justify-content: center;
}

.subscriptionOptions {
  --width: 20rem;

  @include sm {
    --width: 40rem;
  }
  
  margin-top: 2rem;
  padding: 0 2rem;
  
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--width), 1fr));
  place-items: stretch;

  gap: 2rem;
}
</style>
